import React, { useEffect } from "react";
//context
import Context from 'context/';

export const wrapRootElement = ({ element }) => {
	return (
		<Context>{element}</Context>
	);
};

// This component wraps root element and injects the script using useEffect
const ScriptInjector = ({ element }) => {
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		let prefix = params.get('refersionHelperScript') || '';

		// if prefix is not dev- then use the production script
		if (prefix === 'dev-') {

			const scriptSrc = `https://cdn.refersion.com/assets/${prefix}refersion-marketing-site-helper.min.js`;

			// Check if the script is already loaded
			const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);
			if ( !existingScript ) {

				const script = document.createElement('script');
				script.src = scriptSrc;
				script.type = 'text/javascript';
				document.head.appendChild(script);

				// Clean up the script when the component unmounts
				return () =>
				{
					document.head.removeChild(script);
				};

			}
		}
	}, []);

	return element;
};

export const wrapPageElement = ({ element }) => {
	return (
		<Context>
			<ScriptInjector element={element} />
		</Context>
	);
};

//for safari
// export const onClientEntry = async () => {
//   if (typeof IntersectionObserver === "undefined") {
//     await import("intersection-observer")
//   }
// }
